<template>
  <div id="pin-login" class="d-flex flex-column">
    <div class="text-center">
      <img class="my-5" alt="logo" src="@/assets/logo.png" />
    </div>
    <form
      id="form_login"
      class="d-flex flex-column justify-content-between"
      novalidate
    >
      <div>
        <div class="form-group">
          <label for="shop_id">รหัสร้านค้า</label>
          <input
            type="text"
            id="shop_code"
            name="shopCode"
            class="mx-auto form-control text-center"
            inputmode="numeric"
            v-model="shopCode"
            required
          />
          <div class="invalid-feedback">กรุณาใส่รหัสร้านให้ถูกต้อง</div>
        </div>
        <div class="form-group">
          <label for="pinInput">PINCODE</label>
          <input
            type="tel"
            id="pinInput"
            pattern="[0-9]{6}"
            maxlength="6"
            name="pincode"
            inputmode="numeric"
            class="mx-auto form-control text-center"
            v-model="pinCode"
            required
          />
          <div class="invalid-feedback">ต้องเป็นตัวเลข 6 หลัก</div>
        </div>
      </div>
      <button
        id="processOkBtn"
        type="submit"
        style="width: 100%"
        class="btn btn-primary mb-4"
      >
        ตกลง
      </button>
    </form>
  </div>
</template>
<script>
import getJWT from "@/helper/getJWT";
import Vue from "vue";
import jwtDecode from "jwt-decode";

export default {
  data() {
    return {
      shopCode: "",
      pinCode: "",
    };
  },
  methods: {
    // init validate form
    initValidForm() {
      console.log("initValidForm...");
      const form = document.getElementById("form_login");
      // Loop over them and prevent submission
      form.addEventListener(
        "submit",
        (event) => {
          event.preventDefault();
          event.stopPropagation();
          if (form.checkValidity() !== false) {
            // when form validated
            this.login();
          }
          form.classList.add("was-validated");
        },
        false
      );
    },
    async checkJWT(reqBody) {
      console.log("checkJWT...");
      const jwt = await getJWT(reqBody);
      if (!jwt) {
        window.loading(false);
        await this.Swal.fire(
          "ไม่สามารถเข้าสู่ระบบในขณะนี้",
          "กรุณาตรวจสอบข้อมูลหรือติดต่อเจ้าหน้าที่",
          "warning"
        );
        return;
      }

      // save JWT,data to prototype
      Vue.prototype.$JWT = jwt;
      Vue.prototype.$userJWT = jwtDecode(jwt);
      console.log("decode JWT:", Vue.prototype.$userJWT);
      console.log("saved JWT");
      this.$router.push("/menu");
    },
    async login() {
      console.log("login...");
      window.loading(true);

      // set data for request JWT
      let reqBody = {};
      reqBody.channel = "pincode";
      reqBody.shopCode = this.shopCode;
      reqBody.pinCode = this.pinCode;
      await this.checkJWT(reqBody);
    },
  },
  mounted() {
    this.initValidForm();
    window.loading(false);
  },
};
</script>

<style lang="scss" scoped>
#pin-login {
  position: relative;
  height: 100%;
}

#form_login {
  padding: 5px 25px;
  height: 100%;
  display: flex;
}
</style>